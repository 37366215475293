* {
    margin: 0;
    padding: 0;
}

html,
body {
    color: #222;
    font-size: 1rem;
    line-height: 1.4;
    font-family: 'Oxygen', 'Roboto', sans-serif;
    background-color: #1D1D1D;
}

.homepage {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: #ffffff;
}

video,img {
    width: 100%;
    height: auto;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.loading-bar {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #ffffff;
    transform: scaleX(0.3);
    transform-origin: top left;
    transition: transform 0.5s;
}

.loading-bar.ended {
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 1.5s ease-in-out;
}

/* POINTS */

.point {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
}

.point .label {
  position: absolute;
  top: -20px;
  left: -20px;
  padding: 0 1rem;
  border-radius: 1rem;
  background: #00000090;
  border: 1px solid #ffffff77;
  color: #ffffff;
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  line-height: 40px;
  font-weight: 100;
  font-size: 14px;
  cursor: help;
  transform: scale(0, 0);
  transition: all 0.3s ease;
}

.point .text {
  position: absolute;
  top: 40px;
  left: -120px;
  width: 200px;
  padding: 20px;
  border-radius: 1rem;
  background: #000000;
  border: 1px solid #ffffff77;
  color: #ffffff;
  line-height: 1.3em;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 14px;
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;
}

.point:hover .text {
    opacity: 1;
}

.point.visible .label {
    transform: scale(1, 1);
}

/* INFO */

.info{
  position: absolute;
  bottom: calc(0.5rem + 1vw);
  right: calc(0.5rem + 1vw);
  margin: 0;
  padding: 0.5rem 1rem;
  background: #00000090;
  border: 1px solid #ffffff77;
  border-radius: 1rem;
  transition: all 0.3s ease;
  cursor: pointer;
}
.info:hover{
  background: #000000;
}

.close > p{
  display: none;
}
.close::after{
  content: 'x';
  color: #fff;
}

.info-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1b1b1b;
  border-radius: calc(1rem + 1vw);
  padding: calc(1rem + 1vw);
  box-shadow: 5px 5px 20px #1b1b1b60;
  opacity: 0;
  transition: all 0.5s ease;
}

.info p,
.info-text p{
  color: #fff;
  line-height: 1.3em;
}

.social-wrapper{
  margin-top: 1rem;
  justify-content: center;
}

.social-wrapper a{
  padding: 0 1rem;
  width: calc(1rem + 1vw);
  height: auto;
}

.social-wrapper img{
  width: calc(1rem + 1vw);
  height: auto;
}




/* GENERAL */

 .show{
   display: flex !important;
 }

 .show-info{
   opacity: 1;
   z-index: 100;
 }

 .mx-auto{
  margin: 0 auto;
 }

 .flex{
   display: flex;
   flex-direction: row;
 }

 .col{
  float:left;
  box-sizing:border-box;
}

.col-12{
  width:100%;
}

@media (min-width: 64em){
  .lg-col-6{
    width:50%;
  }

  .p-right{
    padding-right: calc(1rem + 1vw);
  }
}

.logo{
  width: calc(2rem + 3vw);
  z-index: 9999;
  padding-top: calc(0.2rem + 0.5vw);
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.container-home{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  justify-content: center;
}

h3{
  font-size: 1.8rem;
  color: #ffffff;
  position: relative;
  z-index: 99989;
  padding: 0;
  top: -35vh;
  left: 45vw;
}

h4{
  font-size: 1.2rem;
  color: #ffffff;
  position: relative;
  text-align: center;
  z-index: 999;
  margin: 0;
  padding: 0;
  top: 15vh;
  left: 0;
  opacity: 0.4;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

.mobile{
  display: none;
}


@media (max-width: 850px) {
	.computer{
    display: none;
  }
  .mobile{
    display: block;
  }
}



/* ALBUM */

.riga{
position: fixed;
width: 100%;
height: calc(2rem + 4.5vw);
left: 0px;
top: 0px;
background-color: #000000;
opacity: 0.5;
z-index: 900;
}

.container{
  max-width: 90rem;
  padding-top: calc(5rem + 5vw);
  padding-left: calc(0.3rem + 1.7vw);
  padding-right: calc(0.3rem + 1.7vw);
}

.container video{
  padding-top: calc(1rem + 1vw);
}

.sotto{
  position: relative;
  background-color: #1D1D1D;
  height: 20vh;
  width: auto;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}



@media (max-width: 850px){

  .flex{
    flex-direction: column;
  }

  .social-wrapper{
    flex-direction: row !important;
  }

  .info-text{
    width: 65vw;
  }

  .info-text p{
    text-align: center;
  }

  .sotto{
    height: 7vh;
  }

}
